import { format } from 'date-fns';
import { NextPage } from 'next';
import Head from 'next/head';
import React, { SyntheticEvent, useEffect, useReducer, useState } from 'react';
import { Button, Col, Container, Modal, ProgressBar, Row } from 'react-bootstrap';

import Alert from '../components/Alert';
import LangSwitch from '../components/LangSwitch';
import LoaderCircle from '../components/LoaderCircle';
import OnboardingForm, { FormValues } from '../components/OnboardingForm';
import SvgCheckmark from '../components/SvgCheckmark';
import useFetch, { FETCH_STATUS_FAILED, FETCH_STATUS_LOADING, FETCH_STATUS_SUCCEEDED } from '../useFetch';
import useLang from '../lib/useLang';
import alertStyles from '../components/Alert.module.css';
import styles from './index.module.css';

type ChangePageAction = { type: 'GOTO_PAGE_1' } | { type: 'GOTO_PAGE_2' } | { type: 'GOTO_PAGE_3' };

type PageState = { page: 1 } | { page: 2 } | { page: 3 };

const reducer = (state: PageState, action: ChangePageAction): PageState => {
  switch (action.type) {
    case 'GOTO_PAGE_1':
      return { page: 1 };
    case 'GOTO_PAGE_2':
      return { page: 2 };
    case 'GOTO_PAGE_3':
      return { page: 3 };
    default:
      throw new Error('Unknown ChangePageAction!');
  }
};

const IndexPage: NextPage = () => {
  const [{ page }, dispatch] = useReducer(reducer, { page: 1 });
  const [tosModalOpened, setTosModalOpened] = useState(false);
  const [progress, setProgress] = useState(0);
  const [, saveFormReqStatus, , execSaveForm] = useFetch('/save-form', 'POST');
  const activeLang = useLang();

  const changeToPage = (changePageAction: 'GOTO_PAGE_1' | 'GOTO_PAGE_2' | 'GOTO_PAGE_3') => {
    dispatch({ type: changePageAction });
  };

  const openTosModal = (ev: SyntheticEvent) => {
    ev.preventDefault();
    setTosModalOpened(true);
  };

  const closeTosModal = (ev: SyntheticEvent) => {
    ev?.preventDefault();
    setTosModalOpened(false);
  };

  const handleFormSubmit = (values: FormValues): void => {
    if (page === 1) {
      dispatch({ type: 'GOTO_PAGE_2' });
      return;
    }
    if (page === 2) {
      dispatch({ type: 'GOTO_PAGE_3' });
      return;
    }
    if (page === 3) {
      const now = new Date();
      // ordered form data
      execSaveForm([
        format(now, 'yyyy-MM-dd HH:mm'),
        values.firstName,
        values.lastName,
        values.streetAddress1,
        values.streetAddress2,
        values.zipCode,
        values.city,
        values.state,
        values.idType,
        values.idValue,
        values.birthDate,
        values.mobilePhone,
        values.personalEmail,
        values.genderIdentification,
        values.raceEthnicIdentification,
        values.employeeEligibility,
        values.uscisNum,
        values.interestedInJob.join(', '),
        values.message,
      ]);
    }
  };

  useEffect(() => {
    setProgress(33.3334 * page);
  }, [page]);

  return (
    <>
      <Head>
        <title>Welcome · Onboarding</title>
      </Head>
      {/* language=CSS */}
      <style jsx global>{`
        .form-row button,
        .form-row input,
        .form-row select,
        .form-row textarea {
          margin-bottom: 1rem;
        }
        .form-row .invalid-tooltip {
          left: 5px;
          top: calc(100% - 1rem);
        }

        .progress-bar {
          background-color: #dcdcdc;
        }
      `}</style>

      <div className={alertStyles.background} />
      <LangSwitch />
      <Container className={styles.container}>
        <Row>
          <Col md={{ offset: 2, span: 3 }} className={styles.graphicWrapper}>
            <img src="/img/logo.png" className={styles.logo} alt="Company logo" />
            <h2 className={styles.graphicContainerTitle}>Application</h2>
            <p>Please submit your information with accuracy to ensure timely application processing. Thank you!</p>
          </Col>
          <Col md={{ span: 5 }} className={styles.formWrapper}>
            <ProgressBar
              now={progress}
              style={{
                right: 0,
                left: 0,
                position: 'absolute',
                borderRadius: 0,
                backgroundColor: 'transparent',
              }}
            />
            <Container className={styles.formContainer}>
              <OnboardingForm
                changeToPage={changeToPage}
                handleSubmit={handleFormSubmit}
                lang={activeLang}
                openTosModal={openTosModal}
                page={page}
                initialValues={{
                  firstName: '',
                  lastName: '',
                  streetAddress1: '',
                  streetAddress2: '',
                  city: '',
                  state: '',
                  zipCode: '',
                  mobilePhone: '',
                  personalEmail: '',
                  idType: '',
                  idValue: '',
                  birthDate: '',
                  genderIdentification: '',
                  raceEthnicIdentification: '',
                  employeeEligibility: '',
                  uscisNum: '',
                  interestedInJob: [],
                  message: '',
                  tos: false,
                }}
              />
            </Container>
          </Col>
        </Row>
      </Container>

      <Modal show={tosModalOpened} onHide={closeTosModal}>
        <Modal.Header closeButton>
          <Modal.Title>Terms and conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            I understand that providing the requested information is not intended to create contractual obligations with
            respect to any matters it covers and that it does not create a contract guaranteeing that I will be employed
            for any specific time period.
          </p>
          <p>
            I acknowledge that the employment relationship between “LIC COM, LLC” and myself is at-will, so that LIC
            COM, LLC or I may terminate my employment at-will, at any time, with or without cause. I also acknowledge
            that the at-will nature of my employment will continue throughout my employment with LIC COM, LLC and can
            only be modified by an express written agreement signed by me and the Director of Operations.
          </p>
          <p>
            No employee, manager or other representative of LIC COM, LLC, has authority to make any promise or
            commitment contrary to the foregoing, or to enter into any agreement - express or implied - with me for
            employment for any specified duration. Any promise, commitment or agreement concerning my employment must be
            in writing and signed by one of the owners of LIC COM, LLC.
          </p>
          <p>
            I acknowledge that I will be under a training assessment period within the first two (2) weeks of
            employment. During this time, I acknowledge that my job performance, timeliness to work, cleanliness,
            overall attitude, adhering to all company rules, and teamwork will be assessed and judged by my Supervisors.
            I acknowledge that any company violation or failure to perform duties during this training period will be
            evaluated and affect my at-will employment.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeTosModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* language=CSS */}
      <style jsx>{`
        .save-info h4 {
          margin: 0;
        }
        .save-info h4 span {
          display: block;
          font-size: 26px;
        }
      `}</style>
      {(saveFormReqStatus === FETCH_STATUS_LOADING ||
        saveFormReqStatus === FETCH_STATUS_FAILED ||
        saveFormReqStatus === FETCH_STATUS_SUCCEEDED) && (
        <Alert>
          {saveFormReqStatus === FETCH_STATUS_LOADING && <LoaderCircle />}
          {saveFormReqStatus === FETCH_STATUS_SUCCEEDED && (
            <>
              <SvgCheckmark />
              <div className="save-info mt-4">
                <h4>
                  <span>Form info successfully saved!</span>Thank you.
                </h4>
              </div>
            </>
          )}
          {saveFormReqStatus === FETCH_STATUS_FAILED && (
            <>
              <div className="save-info">
                <h4>
                  <span>Saving form info failed!</span>We are very sorry. Try again later.
                </h4>
              </div>
            </>
          )}
        </Alert>
      )}
    </>
  );
};

export default IndexPage;
